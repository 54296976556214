import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import PostList from '../components/post-list';
import styled from 'styled-components';
import ShareButtonList from '../components/sharing-button-list';

const HomePage = ({ data }) => {
  const site = data.site;
  const updatePosts = data.updatePosts.nodes;
  const popularPosts = data.popularPosts.nodes;
  const solidityPosts = data.solidityPosts.nodes;
  const rustPosts = data.rustPosts.nodes;
  const motokoPosts = data.motokoPosts.nodes;
  const title = data.markdownRemark.frontmatter.title;
  const description = data.markdownRemark.frontmatter.description;

  return (
    <Layout
      title={title}
      description={description}>

      <Board>
        <h3>人気の記事</h3>
        <PostList posts={popularPosts} />
      </Board>

      <Board>
        <h3>
          <Link to="/solidity/">Solidity ではじめるスマートコントラクト入門</Link>
        </h3>
        <PostList posts={solidityPosts} />
      </Board>
      <Board>
        <h3>
          <Link to="/rust/">Rust ではじめるスマートコントラクト入門</Link>
        </h3>
        <PostList posts={rustPosts} />
      </Board>
      <Board>
        <h3>
          <Link to="/motoko/">Motoko ではじめるキャニスター開発</Link>
        </h3>
        <PostList posts={motokoPosts} />
      </Board>

      <Board>
        <h3>最新の記事</h3>
        <PostList posts={updatePosts} />
      </Board>

      <ShareButtonList
          title={title}
          url={`${site.siteMetadata.siteUrl}`}
        />
    </Layout>
  );
};

export default HomePage;

const Board = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 2rem;
  & h3 {
    font-size: var(--size-600);
    padding-left: 1rem;
    & a {
      color: inherit;
      text-decoration: none;
    }
  }
`;

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    popularPosts: allMarkdownRemark(
      filter: {
        frontmatter: { pinned: { ne: null }},
        fields: { contentType: { eq: "posts" } } 
      }
      sort: { order: ASC, fields: frontmatter___pinned }
      limit: 3
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          title
          tags
        }
      }
    }
    updatePosts: allMarkdownRemark(
      filter: {
        frontmatter: { unlisted: { ne: true }},
        fields: { contentType: { eq: "posts" } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          title
          tags
        }
      }
    }
    solidityPosts: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
            unlisted: { ne: true }
            tags: { in: [
                "Solidity",
                "Chainlink",
                "Ethereum",
                "Hardhat"
            ] } 
        }
        fields: { contentType: { eq: "posts" } }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          tags
          title
        }
        timeToRead
        excerpt
      }
    }
    rustPosts: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
            unlisted: { ne: true }
            tags: { in: [
                "Rust"
            ] } 
        }
        fields: { contentType: { eq: "posts" } }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          tags
          title
        }
        timeToRead
        excerpt
      }
    }
    motokoPosts: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
            unlisted: { ne: true }
            tags: { in: [
                "Motoko"
            ] } 
        }
        fields: { contentType: { eq: "posts" } }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          tags
          title
        }
        timeToRead
        excerpt
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
